import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constant } from 'fp-ts/lib/function';
import { REHYDRATE } from 'redux-persist';
import {
  AttachmentId,
  CommentId,
  FolderId,
  PlaylistId,
  ProjectId,
  ProjectInvitationId,
  SongId,
  WorkspaceId,
  WorkspaceInvitationId,
} from '../../../common/Opaques';
import {
  PrepareUploadResponse,
  PrepareUploadResponseSchema,
} from '../../../common/PresignedRequest';
import { ParseSong, Song, SongListResponseSchema } from '../../../common/Song';
import { forceRefetchOptions } from '../../../common/zod-utils';
import {
  DEVICE_TOKEN_PLATFORM,
  SONG_LIMIT,
} from '../../actions/songs/constants';
import { openDialog } from '../../actions/view/openDialog';
import { ControlStateType } from '../../reducers/types';
import { Collaborator, CollaboratorSchema } from '../../types/Collaborator';
import {
  ControlComment,
  GetControlCommentListResponseSchema,
} from '../../types/ControlComment';
import { Folder, ParseGetFolderListResponse } from '../../types/Folder';
import {
  GetPlaylistListResponseSchema,
  ParsePlaylist,
  Playlist,
} from '../../types/Playlist';
import { ShareLink, ShareLinkSchema } from '../../types/ShareLink';
import { UpdateProfilePayload } from '../../types/UpdateProfilePayload';
import { UserInfo, UserInfoSchema } from '../../types/UserInfo';
import { UserStore, UserStoreResponseSchema } from '../../types/UserStore';
import { WorkspaceProjectRole } from '../../types/WorkspaceProjectRole';
import {
  reportEverywhere,
  tryCatchFinallyReport,
} from '../../utils/error-utils';
import { isNullish } from '../../utils/object-utils';
import { getEnvironment } from '../../utils/os-utils';
import { sortByCreatedAt } from '../../utils/project-utils';
import { sortWorkspaces } from '../../utils/workspace-utils';
import { API_HOST } from '../apiHost';
import { AddUserToProjectData } from './getWorkspaceList';
import {
  AccountManageToken,
  AccountManageTokenSchema,
  SignUpResponse,
  SignUpSchema,
} from './types/Account';
import {
  Attachment,
  AttachmentFolder,
  AttachmentSchema,
  GetSongAttachmentFolderListResponseSchema,
  GetSongAttachmentListResponseSchema,
} from './types/Attachment';
import { Lyrics, LyricsSchema } from './types/Lyrics';
import { ProjectInvitation, ProjectMember } from './types/ProjectMember';
import {
  UpdatePlaylistDescriptionFormField,
  UpdatePlaylistDetailsFormField,
  updatePlaylistInPlace,
} from './types/UpdatePlaylistForm';
import { UpdateFileFormField, updateFileInPlace } from './types/UpdateSongForm';
import {
  UpdateWorkspaceDescriptionFormField,
  UpdateWorkspaceDetailsFormField,
  updateWorkspaceInPlace,
} from './types/UpdateWorkspaceForm';
import {
  GetProjectInvitationListResponseSchema,
  GetProjectMemberListResponseSchema,
  GetWorkspaceInvitationListResponseSchema,
  GetWorkspaceListResponseSchema,
  GetWorkspaceMemberListResponseSchema,
  ParseGetWorkspaceProjectListResponse,
  Project,
  Workspace,
  WorkspaceSchema,
} from './types/Workspace';
import {
  WorkspaceInvitation,
  WorkspaceMember,
  WorkspaceRole,
} from './types/WorkspaceMember';
import {
  GetWorkspaceRoleListResponseSchema,
  WorkspaceRole as WorkspaceRole2,
} from './types/WorkspaceRole';

export function allFromWorkspace(workspaceId: WorkspaceId) {
  return `All_from_workspace_${workspaceId}` as const;
}

function allFromSong(songId: SongId) {
  return `All_from_song_${songId}` as const;
}

function allFromProject(projectId: ProjectId) {
  return `All_from_project_${projectId}` as const;
}

export interface UpdateShareLinkProps {
  disabledDownload: boolean;
  expiresAt: Date | null;
  password: string | null | undefined;
}

export interface UpdatePlaylistShareLinkProps
  extends Partial<UpdateShareLinkProps> {
  playlistId: PlaylistId;
  workspaceId: WorkspaceId;
}
export interface UpdateSongShareLinkProps
  extends Partial<UpdateShareLinkProps> {
  songId: SongId;
  workspaceId: WorkspaceId;
}

export const api = createApi({
  reducerPath: 'api',
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE && (action?.payload as any)?.[reducerPath]) {
      return (action.payload as any)[reducerPath];
    }
    return undefined;
  },
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as ControlStateType;
      const authToken = state.tokens.auth;
      if (authToken) {
        headers.set('Authorization', `Bearer ${authToken}`);
      }
      return headers;
    },
  }),
  keepUnusedDataFor: Number.POSITIVE_INFINITY, // TODO: maybe remove after a while?
  tagTypes: [
    'Attachment',
    'AttachmentFolder',
    'Comment',
    'Folder',
    'Playlist',
    'PlaylistShareLink',
    'Project',
    'ProjectInvitation',
    'ProjectMember',
    'Song',
    'SongShareLink',
    // 'UserStore',
    'WorkspaceInvitation',
  ],
  endpoints: (build) => ({
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Get_workspace_list_for_user}
     */
    getWorkspaces: build.query<Workspace[], void>({
      query: () => ({
        url: `workspace/v2/list`,
      }),
      transformResponse: (response) =>
        sortWorkspaces(GetWorkspaceListResponseSchema.parse(response).records),
    }),
    /**
     *
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Update_workspace}
     */
    updateWorkspace: build.mutation<
      Workspace,
      {
        workspace: Workspace;
        update?:
          | UpdateWorkspaceDetailsFormField
          | UpdateWorkspaceDescriptionFormField;
        artwork?: File | undefined;
      }
    >({
      query: ({ workspace, update, artwork }) => {
        const formData = new FormData();
        if (update && 'title' in update) {
          formData.append('title', update.title);
        }
        if (update && 'description' in update) {
          formData.append('description', update.description);
        }
        if (artwork) {
          formData.append('image', artwork);
        }
        return {
          url: `workspace/${workspace.stringId}/update`,
          method: 'POST',
          body: formData,
          headers: {
            'control-workspace': workspace.stringId,
          },
        };
      },
      transformResponse: (response) => {
        return WorkspaceSchema.parse(response);
      },
      async onQueryStarted(
        { workspace, update },
        { dispatch, queryFulfilled }
      ) {
        const patch = dispatch(
          api.util.updateQueryData('getWorkspaces', undefined, (workspaces) => {
            if (update) {
              for (let i = 0; i < workspaces.length; i += 1) {
                const w = workspaces[i];
                if (w?.stringId === workspace.stringId) {
                  updateWorkspaceInPlace(update, w);
                  break;
                }
              }
            }
          })
        );
        try {
          const { data } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData(
              'getWorkspaces',
              undefined,
              (workspaces) => {
                for (let i = 0; i < workspaces.length; i += 1) {
                  if (workspaces[i]?.stringId === data.stringId) {
                    workspaces[i] = data;
                    break;
                  }
                }
              }
            )
          );
        } catch (e) {
          patch.undo();
          reportEverywhere('updateWorkspace')(e);
          throw e;
        }
      },
    }),
    // *
    // * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Get_project_list}
    // */
    getWorkspaceProjectList: build.query<Project[], WorkspaceId>({
      query: (workspaceId) => ({
        url: `workspace/v2/project/list`,
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response, _error, arg) => {
        return sortByCreatedAt(
          ParseGetWorkspaceProjectListResponse(response, arg)
        );
      },
      providesTags: (result, _error, arg) =>
        result
          ? [
              ...result.map((project) => ({
                type: 'Project' as const,
                id: project.stringId,
              })),
              {
                type: 'Project' as const,
                id: allFromWorkspace(arg),
              },
            ]
          : [
              {
                type: 'Project' as const,
                id: allFromWorkspace(arg),
              },
            ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          api.endpoints.getWorkspaceFolders.initiate(arg, {
            forceRefetch: true,
          })
        );

        dispatch(
          api.endpoints.getWorkspacePlaylists.initiate(arg, {
            forceRefetch: true,
          })
        );

        dispatch(
          api.endpoints.getWorkspaceSongs.initiate(arg, {
            forceRefetch: true,
          })
        );
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Create_project}
     */
    createProject: build.mutation<Project, [WorkspaceId, string, boolean]>({
      query: ([workspaceId, title, isPublic]) => ({
        url: `workspace/v2/project`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          project_title: title,
          is_public: isPublic,
        },
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: 'Project' as const,
          id: allFromWorkspace(args[0]),
        },
      ],
    }),
    // /**
    //  * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-List_workspace_folder_list-List_workspace_folder_listt}
    //  */
    getWorkspaceFolders: build.query<Folder[], WorkspaceId>({
      async queryFn(arg, _api, _extraOptions, fetchWithBQ) {
        const workspaceFolders = await fetchWithBQ({
          url: `workspace/v2/folder/list`,
          headers: {
            'control-workspace': arg,
          },
        });

        if (workspaceFolders.error) {
          return {
            error: workspaceFolders.error,
          };
        }

        return {
          data: ParseGetFolderListResponse(workspaceFolders.data, arg),
        };
      },
      providesTags: (result, _error, arg) =>
        result
          ? [
              ...result.map((folder) => ({
                type: 'Folder' as const,
                id: folder.folderId,
              })),
              {
                type: 'Folder' as const,
                id: allFromWorkspace(arg),
              },
            ]
          : [
              {
                type: 'Folder' as const,
                id: allFromWorkspace(arg),
              },
            ],
    }),
    // /**
    //  * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Create_folder}
    //  */
    createFolder: build.mutation<
      Folder,
      [WorkspaceId, ProjectId | undefined, FolderId | undefined, string]
    >({
      query: ([workspaceId, projectId, folderId, title]) => ({
        url: `folder`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          parent_folder_id: folderId,
          folder_name: title,
          project_id: projectId,
        },
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: 'Folder' as const,
          id: allFromWorkspace(args[0]),
        },
      ],
    }),
    deleteFolder: build.mutation<Folder, Folder>({
      query: (folder) => ({
        url: `folder/${folder.folderId}`,
        method: 'DELETE',
        headers: {
          'control-workspace': folder.workspaceId,
        },
      }),
      invalidatesTags: (_result, _error, folder) => [
        {
          type: 'Folder' as const,
          id: folder.folderId,
        },
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getWorkspaceFolders',
            args.workspaceId,
            (draft) => {
              return draft.filter((p) => p.folderId !== args.folderId);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patch.undo();
          reportEverywhere('deleteFolder')(e);
          api.util.invalidateTags([
            {
              type: 'Folder' as const,
              id: args.folderId,
            },
          ]);
          throw e;
        }
      },
    }),
    renameFolder: build.mutation<Folder[], [WorkspaceId, FolderId, string]>({
      query: ([workspaceId, folderId, name]) => ({
        url: `folder/${folderId}/update`,
        method: 'PUT',
        body: {
          name,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response, _args, [workspaceId]) =>
        ParseGetFolderListResponse(response, workspaceId),
      async onQueryStarted(
        [workspaceId, folderId, name],
        { dispatch, queryFulfilled }
      ) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getWorkspaceFolders',
            workspaceId,
            (draft) => {
              for (let i = 0; i < draft.length; i += 1) {
                const p = draft[i];
                if (p?.folderId === folderId) {
                  p.name = name;
                  break;
                }
              }
            }
          )
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.upsertQueryData('getWorkspaceFolders', workspaceId, data)
          );
        } catch (e) {
          patch.undo();
          reportEverywhere('renameFolder')(e);
          api.util.invalidateTags([
            {
              type: 'Folder' as const,
              id: folderId,
            },
          ]);
          throw e;
        }
      },
    }),
    unpackFolder: build.mutation<Folder, [WorkspaceId, FolderId]>({
      query: ([workspaceId, folderId]) => ({
        url: `folder/${folderId}/unpack`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      invalidatesTags: (_result, _error, [, folderId]) => [
        {
          type: 'Folder' as const,
          id: folderId,
        },
      ],
    }),
    // getFolder: build.query<Folder, FolderId>({
    //   query: (folderId) => ({
    //     url: `folder/${folderId}`,
    //   }),
    //   transformResponse: (response) => {
    //     return FolderChildrenSchema.parse(response);
    //   },
    //   providesTags: (result, _error, folderId) =>
    //     result
    //       ? [
    //           {
    //             type: 'Folder' as const,
    //             id: folderId,
    //           },
    //         ]
    //       : [],
    // }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Update_project}
     */
    updateProject: build.mutation<
      Project,
      {
        workspaceId: WorkspaceId;
        projectId: ProjectId;
        title?: string | null;
        description?: string | null;
        isPublic?: boolean | null;
      }
    >({
      query: ({ workspaceId, projectId, title, description, isPublic }) => ({
        url: `workspace/project/${projectId}/update`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          is_public: isPublic,
          project_description: description,
          title,
        },
      }),
      invalidatesTags: (_result, _error, { projectId }) => [
        {
          type: 'Project' as const,
          id: projectId,
        },
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getWorkspaceProjectList',
            args.workspaceId,
            (draft) => {
              for (let i = 0; i < draft.length; i += 1) {
                const p = draft[i];
                if (p?.stringId === args.projectId) {
                  if (!isNullish(args.isPublic)) {
                    p.isPublic = args.isPublic;
                  }
                  if (!isNullish(args.title)) {
                    p.title = args.title;
                  }
                  if (!isNullish(args.description)) {
                    p.description = args.description;
                  }
                  break;
                }
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patch.undo();
          reportEverywhere('updateProject')(e);
          throw e;
        }
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Delete_project}
     */
    deleteProject: build.mutation<unknown, [WorkspaceId, ProjectId]>({
      query: ([workspaceId, projectId]) => ({
        url: `workspace/project/${projectId}`,
        method: 'DELETE',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      invalidatesTags: (_result, _error, [, projectId]) => [
        {
          type: 'Project' as const,
          id: projectId,
        },
      ],
    }),
    getWorkspacePlaylists: build.query<Playlist[], WorkspaceId>({
      async queryFn(arg, _api, _extraOptions, fetchWinBQ) {
        const workspacePlaylistsResponse = await fetchWinBQ({
          url: `workspace/v2/playlist/list`,
          headers: {
            'control-workspace': arg,
          },
        });

        if (workspacePlaylistsResponse.error) {
          return workspacePlaylistsResponse;
        }

        return {
          data: GetPlaylistListResponseSchema.parse(
            workspacePlaylistsResponse.data
          ).records.map((record) => ({
            ...record,
            workspaceId: arg,
          })),
        };
      },
      providesTags: (result, _error, arg) =>
        result
          ? [
              ...result.map((playlist) => ({
                type: 'Playlist' as const,
                id: playlist.playlistId,
              })),
              {
                type: 'Playlist' as const,
                id: allFromWorkspace(arg),
              },
            ]
          : [
              {
                type: 'Playlist' as const,
                id: allFromWorkspace(arg),
              },
            ],
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Create_playlist}
     */
    createPlaylist: build.mutation<
      Playlist,
      {
        workspaceId: WorkspaceId;
        artwork?: File;
        description: string | undefined;
        folderId: FolderId | undefined;
        projectId: ProjectId | undefined;
        title: string;
      }
    >({
      query: ({
        artwork,
        description,
        folderId,
        projectId,
        title,
        workspaceId,
      }) => {
        const formData = new FormData();
        formData.append('title', title);
        if (description) {
          formData.append('destination', description);
        }
        if (folderId) {
          formData.append('folder_id', folderId);
        }
        if (artwork) {
          formData.append('image', artwork);
        }
        if (projectId) {
          formData.append('project_id', projectId);
        }

        return {
          url: `/playlist`,
          method: 'POST',
          headers: {
            'control-workspace': workspaceId,
          },
          body: formData,
        };
      },
      transformResponse: (response, _error, { workspaceId }) => {
        return ParsePlaylist(response, workspaceId);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: playlist } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              args.workspaceId,
              (draft) => {
                draft.push(playlist);
              }
            )
          );
        } catch (e) {
          reportEverywhere('createPlaylist')(e);
          api.util.invalidateTags([
            {
              type: 'Playlist' as const,
              id: allFromWorkspace(args.workspaceId),
            },
          ]);
          throw e;
        }
      },
    }),
    /**
     * {@link https://staging.api.control.vollume.com/apidoc/#api-Assign_song_to_playlist-Control_API}
     */
    assignSongsToPlaylist: build.mutation<
      Playlist,
      {
        workspaceId: WorkspaceId;
        playlistId: PlaylistId;
        audioIds: SongId[];
        index: number;
      }
    >({
      query: ({ playlistId, audioIds, index, workspaceId }) => ({
        url: `workspace/v2/playlist/${playlistId}`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          song_ids: audioIds,
          song_index: index,
        },
      }),
      transformResponse: (response, _error, { workspaceId }) => {
        return ParsePlaylist(response, workspaceId);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: playlist } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              args.workspaceId,
              (draft) => {
                const index = draft.findIndex(
                  (p) => p.playlistId === playlist.playlistId
                );
                if (index !== -1) {
                  draft[index] = playlist;
                }
              }
            )
          );
        } catch (e) {
          reportEverywhere('assignSongsToPlaylist')(e);
          api.util.invalidateTags([
            {
              type: 'Playlist' as const,
              id: allFromWorkspace(args.workspaceId),
            },
          ]);
          throw e;
        }
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Delete_playlist-Control_API}
     */
    deletePlaylist: build.mutation<void, Playlist>({
      query: (playlist) => ({
        url: `/playlist/${playlist.playlistId}`,
        method: 'DELETE',
        headers: {
          'control-workspace': playlist.workspaceId,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getWorkspacePlaylists',
            args.workspaceId,
            (draft) => {
              return draft.filter((p) => p.playlistId !== args.playlistId);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patch.undo();
          reportEverywhere('deletePlaylist')(e);
          api.util.invalidateTags([
            {
              type: 'Playlist' as const,
              id: allFromWorkspace(args.workspaceId),
            },
          ]);
          throw e;
        }
      },
    }),
    leavePlaylist: build.mutation<
      void,
      {
        playlist: Playlist;
        userId: string;
      }
    >({
      query: ({ playlist, userId }) => ({
        url: `/share/playlist/${playlist.playlistId}/remove`,
        method: 'POST',
        headers: {
          'control-workspace': playlist.workspaceId,
        },
        body: {
          user_id: userId,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        if (args.playlist.workspaceId) {
          try {
            await queryFulfilled;
            dispatch(
              api.util.updateQueryData(
                'getWorkspacePlaylists',
                args.playlist.workspaceId,
                (draft) => {
                  return draft.filter(
                    (p) => p.playlistId !== args.playlist.playlistId
                  );
                }
              )
            );
          } catch (e) {
            reportEverywhere('leavePlaylist')(e);
            throw e;
          }
        }
      },
    }),
    updatePlaylistSongs: build.mutation<
      Playlist,
      {
        playlist: Playlist;
        audioIds: SongId[];
      }
    >({
      query: ({ playlist, audioIds }) => ({
        url: `/playlist/${playlist.playlistId}/update/songs`,
        method: 'POST',
        headers: {
          'control-workspace': playlist.workspaceId,
        },
        body: {
          song_ids: audioIds,
        },
      }),
      transformResponse: (response, _error, { playlist }) => {
        return ParsePlaylist(response, playlist.workspaceId);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        tryCatchFinallyReport(
          'updatePlaylistSongs',
          async () => {
            const patch = dispatch(
              api.util.updateQueryData(
                'getWorkspacePlaylists',
                args.playlist.workspaceId,
                (draft) => {
                  for (let i = 0; i < draft.length; i += 1) {
                    const p = draft[i];
                    if (p?.playlistId === args.playlist.playlistId) {
                      p.songIds = args.audioIds;
                      break;
                    }
                  }
                }
              )
            );
            try {
              const { data: playlist } = await queryFulfilled;
              dispatch(
                api.util.updateQueryData(
                  'getWorkspacePlaylists',
                  playlist.workspaceId,
                  (draft) => {
                    const index = draft.findIndex(
                      (p) => p.playlistId === playlist.playlistId
                    );
                    if (index !== -1) {
                      draft[index] = playlist;
                    }
                  }
                )
              );
            } catch (e) {
              patch.undo();
              reportEverywhere('updatePlaylistSongs')(e);
              throw e;
            }
          },
          () => {
            api.util.invalidateTags([
              {
                type: 'Playlist' as const,
                id: allFromWorkspace(args.playlist.workspaceId),
              },
            ]);
          }
        );
      },
    }),
    updatePlaylist: build.mutation<
      Playlist,
      {
        playlist: Playlist;
        update?:
          | UpdatePlaylistDetailsFormField
          | UpdatePlaylistDescriptionFormField;
        artwork?: File;
      }
    >({
      query: ({ playlist, update, artwork }) => {
        const formData = new FormData();
        if (update && 'title' in update) {
          formData.append('title', update.title);
        }
        if (update && 'description' in update) {
          formData.append('description', update.description);
        }
        if (artwork) {
          formData.append('image', artwork);
        }
        return {
          url: `/playlist/${playlist.playlistId}/update`,
          method: 'POST',
          headers: {
            'control-workspace': playlist.workspaceId,
          },
          body: formData,
        };
      },
      transformResponse: (response, _error, { playlist }) => {
        return ParsePlaylist(response, playlist.workspaceId);
      },
      async onQueryStarted({ playlist, update }, { dispatch, queryFulfilled }) {
        tryCatchFinallyReport(
          'updatePlaylist',
          async () => {
            const patch = dispatch(
              api.util.updateQueryData(
                'getWorkspacePlaylists',
                playlist.workspaceId,
                (draft) => {
                  if (update) {
                    for (let i = 0; i < draft.length; i += 1) {
                      const p = draft[i];
                      if (p?.playlistId === playlist.playlistId) {
                        updatePlaylistInPlace(update, p);
                        break;
                      }
                    }
                  }
                }
              )
            );

            try {
              const { data } = await queryFulfilled;
              dispatch(
                api.util.updateQueryData(
                  'getWorkspacePlaylists',
                  playlist.workspaceId,
                  (draft) => {
                    const index = draft.findIndex(
                      (p) => p.playlistId === playlist.playlistId
                    );

                    if (index !== -1) {
                      draft[index] = data;
                    }
                  }
                )
              );
            } catch (e) {
              patch.undo();
            }
          },
          () => {
            api.util.invalidateTags([
              {
                type: 'Playlist' as const,
                id: allFromWorkspace(playlist.workspaceId),
              },
            ]);
          }
        );
      },
    }),
    /**
     * {@link https://staging.api.control.vollume.com/apidoc/#api-Remove_songs_from_playlist-Control_API}
     */
    removeSongsFromPlaylist: build.mutation<
      Playlist,
      {
        workspaceId: WorkspaceId;
        playlistId: PlaylistId;
        songIds: SongId[];
      }
    >({
      query: ({ playlistId, songIds, workspaceId }) => ({
        url: `/playlist/${playlistId}/songs/remove`,
        method: 'POST',
        body: {
          song_ids: songIds,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response, _error, { workspaceId }) => {
        return ParsePlaylist(response, workspaceId);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: playlist } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              args.workspaceId,
              (draft) => {
                const index = draft.findIndex(
                  (p) => p.playlistId === playlist.playlistId
                );
                if (index !== -1) {
                  draft[index] = playlist;
                } else {
                  draft.push(playlist);
                }
              }
            )
          );
        } catch (e) {
          reportEverywhere('removeSongsFromPlaylist')(e);
          throw e;
        }
      },
    }),
    getWorkspaceSongs: build.query<Song[], WorkspaceId>({
      queryFn: async (arg, _api, _extraOptions, fetchWinBQ) => {
        const workspaceSongs: Song[] = [];
        let workspaceSongsAfterId;
        let lastSongsCount = 0;

        do {
          // eslint-disable-next-line no-await-in-loop
          const songResponse = await fetchWinBQ({
            url: `workspace/v2/song/list`,
            method: 'GET',
            params: {
              after_id: workspaceSongsAfterId,
              limit: SONG_LIMIT,
              // for v3
              // full_response: true,
            },
            headers: {
              'control-workspace': arg,
            },
          });

          if (songResponse.error) {
            return songResponse;
          }

          const parsedSongs = SongListResponseSchema.parse(
            songResponse.data
          ).records.map((record) => ({
            ...record,
            workspaceId: arg,
          }));

          if (parsedSongs.length > 0) {
            workspaceSongsAfterId =
              parsedSongs[parsedSongs.length - 1]?.audioId || undefined;
          }

          workspaceSongs.push(...parsedSongs);

          lastSongsCount = parsedSongs.length;
        } while (lastSongsCount === SONG_LIMIT);

        // const projectSongs: Song[] = [];
        // let projectSongsAfterId;

        // const projects: Project[] =
        //   api.endpoints.getWorkspaceProjectList.select(arg)(getState() as any)
        //     .data ?? [];
        // let lastSongsCount = 0;
        // // eslint-disable-next-line no-restricted-syntax
        // for (const project of projects) {
        //   do {
        //     // eslint-disable-next-line no-await-in-loop
        //     const songResponse = await fetchWinBQ({
        //       url: `workspace/project/${project.stringId}/song/list`,
        //       method: 'GET',
        //       params: {
        //         after_id: projectSongsAfterId,
        //         limit: SONG_LIMIT,
        //       },
        //       headers: {
        //         'control-workspace': arg,
        //       },
        //     });

        //     if (songResponse.error) {
        //       return songResponse;
        //     }

        //     const { records: parsedSongs } = SongListResponseSchema.parse(
        //       songResponse.data
        //     );

        //     if (parsedSongs.length > 0) {
        //       projectSongsAfterId =
        //         parsedSongs[parsedSongs.length - 1]?.audioId || null;
        //     }

        //     projectSongs.push(...parsedSongs);
        //     lastSongsCount = parsedSongs.length;
        //   } while (lastSongsCount === SONG_LIMIT);
        // }

        // return { data: workspaceSongs.concat(projectSongs) };
        return { data: workspaceSongs };
      },
      providesTags: (result, _error, arg) => {
        if (result) {
          return [
            {
              type: 'Song' as const,
              id: allFromWorkspace(arg),
            },
          ];
        }
        return [];
      },
    }),
    /**
     * {@link https://staging.api.control.vollume.com/apidoc/#api-Delete_Songs-Control_API}
     */
    deleteFiles: build.mutation<
      void,
      {
        workspaceId: WorkspaceId;
        songIds: SongId[];
      }
    >({
      query: ({ songIds, workspaceId }) => ({
        url: '/songs/delete',
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          song_ids: songIds,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        tryCatchFinallyReport(
          'deleteSongs',
          async () => {
            const patch = dispatch(
              api.util.updateQueryData(
                'getWorkspaceSongs',
                args.workspaceId,
                (draft) =>
                  draft.filter((s) => !args.songIds.includes(s.audioId))
              )
            );
            try {
              await queryFulfilled;
            } catch (e) {
              patch.undo();
            }
          },
          () => {
            api.util.invalidateTags([
              {
                type: 'Song' as const,
                id: allFromWorkspace(args.workspaceId),
              },
            ]);
          }
        );
      },
    }),
    favoriteSong: build.mutation<
      void,
      {
        workspaceId: WorkspaceId;
        songIds: SongId[];
      }
    >({
      query: ({ songIds, workspaceId }) => ({
        url: `songs/v2/list/favorite`,
        method: 'POST',
        body: {
          song_ids: songIds,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getWorkspaceSongs',
            args.workspaceId,
            (draft) => {
              draft.forEach((s) => {
                if (args.songIds.includes(s.audioId)) {
                  s.isFavorite = true;
                }
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patch.undo();
          reportEverywhere('favoriteSong')(e);
          throw e;
        }
      },
    }),
    unfavoriteSong: build.mutation<
      void,
      {
        workspaceId: WorkspaceId;
        songIds: SongId[];
      }
    >({
      query: ({ songIds, workspaceId }) => ({
        url: `songs/v2/list/unfavorite`,
        method: 'POST',
        body: {
          song_ids: songIds,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getWorkspaceSongs',
            args.workspaceId,
            (draft) => {
              draft.forEach((s) => {
                if (args.songIds.includes(s.audioId)) {
                  s.isFavorite = false;
                }
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patch.undo();
          reportEverywhere('unfavoriteSong')(e);
          throw e;
        }
      },
    }),
    /*
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Update_list_of_audio_files-Control_API}
     */
    updateListOfAudioFiles: build.mutation<
      Song[],
      {
        workspaceId: WorkspaceId;
        songIds: SongId[];
        update: UpdateFileFormField;
      }
    >({
      query: ({ songIds, update, workspaceId }) => ({
        url: `/song/list/update`,
        method: 'POST',
        body: {
          song_ids: songIds,
          update,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response, _error, args) => {
        return SongListResponseSchema.parse(response).records.map((record) => ({
          ...record,
          workspaceId: args.workspaceId,
        }));
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            'getWorkspaceSongs',
            args.workspaceId,
            (draft) => {
              draft.forEach((song) => {
                if (args.songIds.includes(song.audioId)) {
                  updateFileInPlace(args.update, song);
                }
              });
            }
          )
        );

        try {
          const { data: songs } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspaceSongs',
              args.workspaceId,
              (draft) => {
                songs.forEach((song) => {
                  const index = draft.findIndex(
                    (s) => s.audioId === song.audioId
                  );
                  if (index !== -1) {
                    draft[index] = song;
                  } else {
                    draft.push(song);
                  }
                });
              }
            )
          );
        } catch (e) {
          patchResult.undo();
          reportEverywhere('updateListOfAudioFiles')(e);
          throw e;
        }
      },
    }),
    uploadAudioArtwork: build.mutation<
      Song,
      {
        workspaceId: WorkspaceId;
        songId: SongId;
        image: Blob;
      }
    >({
      query: ({ songId, image, workspaceId }) => {
        const formData = new FormData();
        formData.append('image', image);
        return {
          url: `/upload/artwork/${songId}`,
          method: 'POST',
          body: formData,
          headers: {
            'control-workspace': workspaceId,
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      transformResponse: (response, _error, args) => {
        return ParseSong(response, args.workspaceId);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: song } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspaceSongs',
              args.workspaceId,
              (draft) => {
                const index = draft.findIndex(
                  (s) => s.audioId === song.audioId
                );
                if (index !== -1) {
                  draft[index] = song;
                } else {
                  draft.push(song);
                }
              }
            )
          );
        } catch (e) {
          reportEverywhere('uploadAudioArtwork')(e);
          throw e;
        }
      },
    }),
    finishUpload: build.mutation<
      Song,
      {
        workspaceId: WorkspaceId;
        projectId: ProjectId | undefined;
        playlistId: PlaylistId | undefined;
        songId: SongId;
        key: string;
      }
    >({
      query: ({ songId, key, workspaceId }) => ({
        url: `/upload/finish`,
        method: 'POST',
        body: {
          audio_id: songId,
          key,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response, _error, args) => {
        return ParseSong(response, args.workspaceId);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: song } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspaceSongs',
              args.workspaceId,
              (draft) => {
                const index = draft.findIndex(
                  (s) => s.audioId === song.audioId
                );
                if (index !== -1) {
                  draft[index] = song;
                } else {
                  draft.push(song);
                }
              }
            )
          );
          if (args.playlistId) {
            dispatch(
              api.endpoints.getWorkspacePlaylists.initiate(
                args.workspaceId,
                forceRefetchOptions
              )
            );
          }
        } catch (e) {
          reportEverywhere('finishUpload')(e);
          throw e;
        }
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Upload_S3_Finish-Control_API}
     */
    finishAttachmentUpload: build.mutation<
      Attachment,
      {
        workspaceId: WorkspaceId;
        songId: SongId;
        key: string;
        attachmentId: AttachmentId;
      }
    >({
      query: ({ songId, key, attachmentId, workspaceId }) => ({
        url: `/song/${songId}/attachment/upload/finish`,
        method: 'POST',
        body: {
          key,
          attachment_id: attachmentId,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return AttachmentSchema.parse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: attachment } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getSongAttachment',
              args.songId,
              (draft) => {
                const index = draft.findIndex(
                  (a) => a.stringId === attachment.stringId
                );
                if (index !== -1) {
                  draft[index] = attachment;
                } else {
                  draft.push(attachment);
                }
              }
            )
          );
        } catch (e) {
          reportEverywhere('finishAttachmentUpload')(e);
          throw e;
        }
      },
    }),
    /**
     *
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Songs-Get_song_attachment_list}
     */
    getSongAttachment: build.query<Attachment[], SongId>({
      query: (songId) => ({
        url: `/song/${songId}/attachment/list`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return GetSongAttachmentListResponseSchema.parse(response).records;
      },
      providesTags: (result, _error, arg) =>
        result
          ? result
              .map((attachment) => ({
                type: 'Attachment' as const,
                id: attachment.stringId as string,
              }))
              .concat([{ type: 'Attachment' as const, id: allFromSong(arg) }])
          : [{ type: 'Attachment' as const, id: allFromSong(arg) }],
    }),
    // TODO: mock api need backend implementation
    getSongAttachmentFolder: build.query<AttachmentFolder[], SongId>({
      query: (songId) => ({
        url: `/song/${songId}/attachment/folder/list`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return GetSongAttachmentFolderListResponseSchema.parse(response)
          .records;
      },
      providesTags: (result, _error, arg) =>
        result
          ? result
              .map((attachmentFolder) => ({
                type: 'AttachmentFolder' as const,
                id: attachmentFolder.stringId as string,
              }))
              .concat([
                { type: 'AttachmentFolder' as const, id: allFromSong(arg) },
              ])
          : [{ type: 'AttachmentFolder' as const, id: allFromSong(arg) }],
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Songs-Delete_song_attachment}
     */
    deleteSongAttachment: build.mutation<
      Attachment[],
      {
        songId: SongId;
        attachmentId: AttachmentId;
      }
    >({
      query: ({ songId, attachmentId }) => ({
        url: `/song/${songId}/attachment/${attachmentId}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => {
        return GetSongAttachmentListResponseSchema.parse(response).records;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getSongAttachment',
              args.songId,
              constant(data)
            )
          );
        } catch (e) {
          reportEverywhere('deleteSongAttachment')(e);
          throw e;
        }
      },
    }),
    /**
     *
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Get_project_member_list}
     */
    getProjectMembers: build.query<
      ProjectMember[],
      { workspaceId: WorkspaceId; projectId: ProjectId }
    >({
      query: ({ workspaceId, projectId }) => ({
        url: `/workspace/v2/project/${projectId}/member/list`,
        method: 'GET',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetProjectMemberListResponseSchema.parse(response).records;
      },
      providesTags: (_result, _error, arg) => [
        {
          type: 'ProjectMember' as const,
          id: arg.projectId,
        },
      ],
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Add_user_to_project}
     */
    addUserToProject: build.mutation<
      void,
      {
        workspaceId: WorkspaceId;
        projectId: ProjectId;
        data: AddUserToProjectData;
      }
    >({
      query: ({ workspaceId, projectId, data }) => ({
        url: `/workspace/v2/project/${projectId}/member/add`,
        method: 'POST',
        body: data,
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        {
          type: 'ProjectMember' as const,
          id: arg.projectId,
        },
      ],
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-GUpdate_project_member_role}
     */
    updateProjectMemberRole: build.mutation<
      ProjectMember[],
      {
        workspaceId: WorkspaceId;
        projectId: ProjectId;
        userId: string;
        role: WorkspaceProjectRole;
      }
    >({
      query: ({ workspaceId, projectId, userId, role }) => ({
        url: `/workspace/project/${projectId}/member/role`,
        method: 'PUT',
        body: {
          user_id: userId,
          role,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetProjectMemberListResponseSchema.parse(response).records;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getProjectMembers',
              { workspaceId: args.workspaceId, projectId: args.projectId },
              constant(data)
            )
          );
        } catch (e) {
          reportEverywhere('updateProjectMemberRole')(e);
          throw e;
        }
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Remove_user_from_project}
     */
    removeMemberFromProject: build.mutation<
      ProjectMember[],
      {
        workspaceId: WorkspaceId;
        projectId: ProjectId;
        userId: string;
      }
    >({
      query: ({ workspaceId, projectId, userId }) => ({
        url: `/workspace/v2/project/${projectId}/member/remove`,
        method: 'POST',
        body: {
          user_id: userId,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetProjectMemberListResponseSchema.parse(response).records;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getProjectMembers',
            { workspaceId: args.workspaceId, projectId: args.projectId },
            (draft) =>
              draft.filter((member) => member.user.userId !== args.userId)
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patch.undo();
          reportEverywhere('updateProjectMemberRole')(e);
          throw e;
        }
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Get_project_invitation_list}
     */
    getProjectInvitations: build.query<
      ProjectInvitation[],
      { workspaceId: WorkspaceId; projectId: ProjectId }
    >({
      query: ({ workspaceId, projectId }) => ({
        url: `/workspace/project/${projectId}/invitation/list`,
        method: 'GET',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetProjectInvitationListResponseSchema.parse(response).records;
      },
      providesTags: (result, _error, arg) =>
        result
          ? result
              .map((invitation) => ({
                type: 'ProjectInvitation' as const,
                id: invitation.invitationId as string,
              }))
              .concat([
                {
                  type: 'ProjectInvitation' as const,
                  id: allFromProject(arg.projectId),
                },
              ])
          : [
              {
                type: 'ProjectInvitation' as const,
                id: allFromProject(arg.projectId),
              },
            ],
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Remove_invitation_to_project}
     */
    removeInvitationToProject: build.mutation<
      void, // TODO: prove what does api actually returns
      {
        workspaceId: WorkspaceId;
        projectId: ProjectId;
        invitationId: ProjectInvitationId;
      }
    >({
      query: ({ workspaceId, projectId, invitationId }) => ({
        url: `/workspace/project/${projectId}/invitation/remove`,
        method: 'POST',
        body: {
          invitation_id: invitationId,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        {
          type: 'ProjectInvitation' as const,
          id: arg.invitationId,
        },
      ],
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Reinvite_invitation_to_project}
     */
    reinviteInvitationToProject: build.mutation<
      unknown, // TODO: prove what does api actually returns
      {
        workspaceId: WorkspaceId;
        projectId: ProjectId;
        email: string;
      }
    >({
      query: ({ workspaceId, projectId, email }) => ({
        url: `/workspace/project/${projectId}/invitation/reinvite`,
        method: 'POST',
        body: {
          email,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        {
          type: 'ProjectInvitation' as const,
          id: allFromProject(arg.projectId),
        },
      ],
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Get_member_list_for_workspace}
     */
    getWorkspaceMembers: build.query<WorkspaceMember[], WorkspaceId>({
      query: (workspaceId) => ({
        url: `/workspace/member/list`,
        method: 'GET',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetWorkspaceMemberListResponseSchema.parse(response).records;
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Change_workspace_member_role}
     */
    changeWorkspaceMemberRole: build.mutation<
      WorkspaceMember[],
      {
        workspaceId: WorkspaceId;
        userId: string;
        role: WorkspaceRole;
      }
    >({
      query: ({ workspaceId, userId, role }) => ({
        url: `/workspace/member/role`,
        method: 'POST',
        body: {
          user_id: userId,
          role,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetWorkspaceMemberListResponseSchema.parse(response).records;
      },
      onQueryStarted(args, { dispatch, queryFulfilled }) {
        // eslint-disable-next-line promise/catch-or-return
        queryFulfilled.then(
          // eslint-disable-next-line promise/always-return
          ({ data }) => {
            dispatch(
              api.util.updateQueryData(
                'getWorkspaceMembers',
                args.workspaceId,
                constant(data)
              )
            );
          },
          (e) => {
            // only way to get the error property typed is with this callback
            reportEverywhere('changeWorkspaceMemberRole')(e);
            if ((e.error as any).status === 403) {
              dispatch(openDialog({ type: 'permission denied' }));
            }
            throw e;
          }
        );
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Delete_workspace_member}
     */
    deleteWorkspaceMember: build.mutation<
      WorkspaceMember[],
      {
        workspaceId: WorkspaceId;
        userId: string;
      }
    >({
      query: ({ workspaceId, userId }) => ({
        url: `/workspace/member/remove`,
        method: 'POST',
        body: {
          user_id: userId,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetWorkspaceMemberListResponseSchema.parse(response).records;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getWorkspaceMembers',
            args.workspaceId,
            (draft) =>
              draft.filter((member) => member.user.userId !== args.userId)
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patch.undo();
          reportEverywhere('deleteWorkspaceMember')(e);
          throw e;
        }
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Get_list_of_workspace_invitations}
     */
    getWorkspaceInvitations: build.query<WorkspaceInvitation[], WorkspaceId>({
      query: (workspaceId) => ({
        url: `/workspace/${workspaceId}/invitation/list`,
        method: 'GET',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetWorkspaceInvitationListResponseSchema.parse(response).records;
      },
      providesTags: (_result, _error, arg) => [
        {
          type: 'WorkspaceInvitation' as const,
          id: allFromWorkspace(arg),
        },
      ],
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Change_workspace_invitation}
     */
    changeWorkspaceInvitation: build.mutation<
      WorkspaceInvitation[],
      {
        workspaceId: WorkspaceId;
        invitationId: WorkspaceInvitationId;
        role: WorkspaceRole;
      }
    >({
      query: ({ workspaceId, invitationId, role }) => ({
        url: `/workspace/invitation/${invitationId}`,
        method: 'POST',
        body: {
          role,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetWorkspaceInvitationListResponseSchema.parse(response).records;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getWorkspaceInvitations',
            args.workspaceId,
            (draft) => {
              for (let i = 0; i < draft.length; i += 1) {
                const invite = draft[i];
                if (invite?.invitationId === args.invitationId) {
                  invite.role = args.role;
                  break;
                }
              }
            }
          )
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspaceInvitations',
              args.workspaceId,
              constant(data)
            )
          );
        } catch (e) {
          patch.undo();
          reportEverywhere('changeWorkspaceInvitation')(e);
          throw e;
        }
      },
    }),
    reinviteToWorkspace: build.mutation<
      WorkspaceInvitation[],
      {
        workspaceId: WorkspaceId;
        invitationId: WorkspaceInvitationId;
      }
    >({
      query: ({ workspaceId, invitationId }) => ({
        url: `/workspace/invitation/${invitationId}/reinvite`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Invite_member_to_a_workspace}
     */
    inviteMemberToAWorkspace: build.mutation<
      WorkspaceInvitation[],
      {
        workspaceId: WorkspaceId;
        email?: string;
        emails?: string[];
        role?: WorkspaceRole;
      }
    >({
      query: ({ workspaceId, email, emails, role }) => ({
        url: `/workspace/${workspaceId}/invite`,
        method: 'POST',
        body: {
          email,
          emails,
          role,
        },
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetWorkspaceInvitationListResponseSchema.parse(response).records;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspaceInvitations',
              args.workspaceId,
              constant(data)
            )
          );
        } catch (e) {
          reportEverywhere('inviteMemberToAWorkspace')(e);
          throw e;
        }
      },
    }),
    /**
     * {@link https://trn-ctrl-api-staging-ws.herokuapp.com/apidoc/#api-Workspace-Remove_workspace_invitation}
     */
    removeWorkspaceInvitation: build.mutation<
      WorkspaceInvitation[],
      {
        workspaceId: WorkspaceId;
        invitationId: WorkspaceInvitationId;
      }
    >({
      query: ({ workspaceId, invitationId }) => ({
        url: `/workspace/invitation/${invitationId}`,
        method: 'DELETE',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetWorkspaceInvitationListResponseSchema.parse(response).records;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspaceInvitations',
              args.workspaceId,
              constant(data)
            )
          );
        } catch (e) {
          reportEverywhere('removeWorkspaceInvitation')(e);
          throw e;
        }
      },
    }),
    getWorkspaceRoles: build.query<WorkspaceRole2[], WorkspaceId>({
      query: (workspaceId) => ({
        url: `/workspace/role/list`,
        method: 'GET',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetWorkspaceRoleListResponseSchema.parse(response).records;
      },
    }),
    inviteWorkspaceMember: build.mutation<
      unknown,
      { workspaceId: WorkspaceId; roleId: string; email: string }
    >({
      query: ({ workspaceId, roleId, email }) => ({
        url: `/workspace/role/${roleId}/user/add`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          role_id: roleId,
          email,
        },
      }),
      invalidatesTags: (_result, _error, { workspaceId }) => [
        {
          type: 'WorkspaceInvitation',
          id: allFromWorkspace(workspaceId),
        },
      ],
    }),
    getPlaylistShareLink: build.query<
      ShareLink,
      {
        playlistId: PlaylistId;
        workspaceId: WorkspaceId;
      }
    >({
      query: ({ playlistId, workspaceId }) => ({
        url: `/share/playlist/${playlistId}/link`,
        method: 'GET',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => ShareLinkSchema.parse(response),
      providesTags: (_result, _error, { playlistId }) => {
        return [{ type: 'PlaylistShareLink', id: playlistId }];
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              args.workspaceId,
              (draft) => {
                const playlist = draft.find(
                  (p) => p.playlistId === args.playlistId
                );
                if (playlist) {
                  playlist.shareId = data.shareId;
                  playlist.publicLinkUrl = data.shareUrl;
                }
              }
            )
          );
        } catch (e) {
          reportEverywhere('getPlaylistShareLink')(e);
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              args.workspaceId,
              (draft) => {
                const playlist = draft.find(
                  (p) => p.playlistId === args.playlistId
                );
                if (playlist) {
                  playlist.shareId = null;
                  playlist.publicLinkUrl = null;
                }
              }
            )
          );
          throw e;
        }
      },
    }),
    sharePlaylistViaPublicLink: build.mutation<
      ShareLink,
      {
        playlistId: PlaylistId;
        workspaceId: WorkspaceId;
      }
    >({
      query: ({ playlistId, workspaceId }) => ({
        url: `/share/playlist/${playlistId}/link`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          disabled_download: false,
        },
      }),
      transformResponse: (response) => ShareLinkSchema.parse(response),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await dispatch(
            api.util.upsertQueryData(
              'getPlaylistShareLink',
              {
                playlistId: args.playlistId,
                workspaceId: args.workspaceId,
              },
              data
            )
          );
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              args.workspaceId,
              (draft) => {
                const playlist = draft.find(
                  (p) => p.playlistId === args.playlistId
                );
                if (playlist) {
                  playlist.shareId = data.shareId;
                  playlist.publicLinkUrl = data.shareUrl;
                }
              }
            )
          );
        } catch (e) {
          reportEverywhere('sharePlaylistViaPublicLink')(e);
          throw e;
        }
      },
    }),
    deletePlaylistShareLink: build.mutation<
      void,
      {
        playlistId: PlaylistId;
        workspaceId: WorkspaceId;
      }
    >({
      query: ({ playlistId, workspaceId }) => ({
        url: `/share/playlist/${playlistId}/link`,
        method: 'DELETE',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      invalidatesTags: (_result, _error, { playlistId }) => [
        { type: 'PlaylistShareLink', id: playlistId },
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              args.workspaceId,
              (draft) => {
                const playlist = draft.find(
                  (p) => p.playlistId === args.playlistId
                );
                if (playlist) {
                  playlist.shareId = null;
                  playlist.publicLinkUrl = null;
                }
              }
            )
          );
        } catch (e) {
          reportEverywhere('deletePlaylistShareLink')(e);
          throw e;
        }
      },
    }),
    updatePlaylistShareLink: build.mutation<
      ShareLink,
      UpdatePlaylistShareLinkProps
    >({
      query: ({
        disabledDownload,
        expiresAt,
        password,
        playlistId,
        workspaceId,
      }) => ({
        url: `/share/playlist/${playlistId}/link`,
        method: 'PUT',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          password,
          expires_at: expiresAt,
          disabled_download: disabledDownload,
        },
      }),
      transformResponse: (response) => ShareLinkSchema.parse(response),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getPlaylistShareLink',
            {
              playlistId: args.playlistId,
              workspaceId: args.workspaceId,
            },
            (prev) => {
              if ('password' in args) {
                prev.password = args.password ?? null;
              }
              if ('expiresAt' in args) {
                prev.expiresAt = args.expiresAt ?? null;
              }
              if ('disabledDownload' in args) {
                prev.disabledDownload = args.disabledDownload ?? false;
              }
            }
          )
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getPlaylistShareLink',
              {
                playlistId: args.playlistId,
                workspaceId: args.workspaceId,
              },
              constant(data)
            )
          );
        } catch (e) {
          patch.undo();
          reportEverywhere('updatePlaylistShareLink')(e);
          throw e;
        }
      },
    }),
    getComments: build.query<ControlComment[], [WorkspaceId, SongId]>({
      query: ([workspaceId, audioId]) => ({
        url: `/comments/v2/${audioId}`,
        method: 'GET',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => {
        return GetControlCommentListResponseSchema.parse(response).records;
      },
      providesTags: () => ['Comment'],
    }),
    postComment: build.mutation<
      ControlComment[],
      {
        audioId: SongId;
        comment: string;
        timeStamp: number | null;
        workspaceId: WorkspaceId;
      }
    >({
      query: ({ audioId, comment, timeStamp, workspaceId }) => ({
        url: `/comments/v2/${audioId}`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          comment,
          timestamp: timeStamp,
        },
      }),
      transformResponse: (response) => {
        return GetControlCommentListResponseSchema.parse(response).records;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getComments',
              [args.workspaceId, args.audioId],
              constant(data)
            )
          );
        } catch (e) {
          reportEverywhere('removeWorkspaceInvitation')(e);
          throw e;
        }
      },
      // invalidatesTags: () => ['Comment'],
    }),
    deleteComment: build.mutation<void, CommentId>({
      query: (commentId) => ({
        url: `/comments/v2/${commentId}`,
        method: 'DELETE',
      }),
      // note: no way to remove a single comment from the cache created from optimistic update
      invalidatesTags: () => ['Comment'],
    }),
    getLyrics: build.query<Lyrics, SongId>({
      query: (audioId) => ({
        url: `/audio/${audioId}/lyrics`,
        method: 'GET',
      }),
      transformResponse: (response, _error) => {
        return LyricsSchema.parse(response);
      },
    }),
    saveLyrics: build.mutation<
      Lyrics,
      {
        audioId: SongId;
        lyrics: string;
      }
    >({
      query: ({ audioId, lyrics }) => ({
        url: `/audio/${audioId}/lyrics`,
        method: 'POST',
        // body: lyrics,
        body: {
          lyrics,
        },
        // params: {
        //   lyrics,
        // },
      }),
      transformResponse: (response, _error) => {
        return LyricsSchema.parse(response);
      },
    }),
    updateProfile: build.mutation<undefined, UpdateProfilePayload>({
      query: (payload) => ({
        url: `user`,
        method: 'PUT',
        body: payload,
      }),
    }),
    signOut: build.mutation<undefined, string | undefined>({
      query: (deviceToken) => ({
        url: `logout`,
        method: 'POST',
        body: {
          device_token: deviceToken,
          device_token_platform: DEVICE_TOKEN_PLATFORM,
        },
      }),
    }),
    getStore: build.query<UserStore, void>({
      query: () => ({
        url: `user/store`,
        method: 'GET',
      }),
      transformResponse: (response) =>
        UserStoreResponseSchema.parse(response).records,
    }),
    setStore: build.mutation<UserStore, UserStore>({
      query: (body) => ({
        url: `user/store`,
        method: 'POST',
        body: Object.fromEntries(
          Object.entries(body).map(([k, v]) => [k, JSON.stringify(v)])
        ),
      }),
      transformResponse: (response) =>
        UserStoreResponseSchema.parse(response).records,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData('getStore', undefined, constant(args))
        );
        try {
          const resp = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getStore', undefined, constant(resp.data))
          );
        } catch (e) {
          patch.undo();
          reportEverywhere('setStore')(e);
          // api.util.invalidateTags(['UserStore'] as const);
          throw e;
        }
      },
    }),
    getUserInfo: build.query<UserInfo, void>({
      query: () => ({
        url: `/user/info/list`,
        method: 'GET',
      }),
      transformResponse: (response) => UserInfoSchema.parse(response),
    }),
    updateCompletedStep: build.mutation<UserInfo, string>({
      query: (stepId) => ({
        url: `/user/steps/complete`,
        method: 'POST',
        body: {
          step_id: stepId,
        },
      }),
    }),
    login: build.mutation<
      SignUpResponse,
      {
        email: string;
        password: string;
        deviceToken?: string;
      }
    >({
      async queryFn(
        { email, password, deviceToken },
        _api,
        _extraOptions,
        fetchWithBQ
      ) {
        const deviceType = (await window.nativeAPI.system()).model;

        const response = await fetchWithBQ({
          url: 'login',
          method: 'POST',
          body: {
            device_identifier: window.nativeAPI.machineIdSync(),
            device_name: window.nativeAPI.os.hostname(),
            device_os_name: getEnvironment().os,
            device_os_version: window.nativeAPI.os.release(),
            device_platform: window.nativeAPI.os.platform(),
            device_token: deviceToken,
            device_token_platform: DEVICE_TOKEN_PLATFORM,
            device_type: deviceType,
            email,
            password,
          },
        });

        if (response.error) {
          return { error: response.error };
        }
        return { data: SignUpSchema.parse(response.data) };
      },
    }),
    signUp: build.mutation<
      SignUpResponse,
      {
        artistName: string;
        deviceIdentifier: string;
        deviceName: string;
        deviceOsName: string;
        deviceOsVersion: string;
        devicePlatform: string;
        deviceToken: string;
        deviceType: string;
        email: string;
        name: string;
        password: string;
      }
    >({
      query: (args) => ({
        url: `/signup`,
        method: 'POST',
        body: {
          artist_name: args.artistName,
          device_identifier: args.deviceIdentifier,
          device_name: args.deviceName,
          device_os_name: args.deviceOsName,
          device_os_version: args.deviceOsVersion,
          device_platform: args.devicePlatform,
          device_token: args.deviceToken,
          device_token_platform: args.deviceType,
          device_type: args.deviceType,
          email: args.email,
          name: args.name,
          password: args.password,
        },
      }),
      transformResponse: (response) => SignUpSchema.parse(response),
    }),
    moveResource: build.mutation<
      void,
      {
        resourceIds: string[];
        targetResourceId: string | null;
        workspaceId: WorkspaceId;
      }
    >({
      query: (args) => ({
        url: `workspace/v2/resource/move`,
        method: 'POST',
        headers: {
          'control-workspace': args.workspaceId,
        },
        body: {
          resource_ids: args.resourceIds,
          target_resource_id: args.targetResourceId,
        },
      }),
    }),
    markPlaylistAsRead: build.mutation<Playlist, [PlaylistId, WorkspaceId]>({
      query: ([playlistId, workspaceId]) => ({
        url: `playlist/${playlistId}/read`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response, _error, [, workspaceId]) => {
        return ParsePlaylist(response, workspaceId);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getWorkspacePlaylists',
            args[1],
            (draft) => {
              for (let i = 0; i < draft.length; i += 1) {
                const playlist = draft[i];
                if (playlist?.playlistId === args[0]) {
                  playlist.isUnread = false;
                  break;
                }
              }
            }
          )
        );
        try {
          const { data: resp } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              args[1],
              (draft) => {
                for (let i = 0; i < draft.length; i += 1) {
                  if (draft[i]?.playlistId === args[0]) {
                    draft[i] = resp;
                    break;
                  }
                }
              }
            )
          );
        } catch (e) {
          patch.undo();
          reportEverywhere('markPlaylistAsRead')(e);
          throw e;
        }
      },
    }),
    getSongShareLink: build.query<
      ShareLink,
      {
        songId: SongId;
        workspaceId: WorkspaceId;
      }
    >({
      query: ({ songId, workspaceId }) => ({
        url: `/share/audio/${songId}/link`,
        method: 'GET',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      transformResponse: (response) => ShareLinkSchema.parse(response),
      providesTags: (_result, _error, { songId }) => {
        return [{ type: 'SongShareLink', id: songId }];
      },
    }),
    createSongPublicLink: build.mutation<
      ShareLink,
      { workspaceId: WorkspaceId; songId: SongId }
    >({
      query: ({ workspaceId, songId }) => ({
        url: `/share/audio/${songId}/link`,
        method: 'POST',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          disabled_download: false,
        },
      }),
      transformResponse: (response) => ShareLinkSchema.parse(response),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await dispatch(
            api.util.upsertQueryData('getSongShareLink', args, data)
          );
        } catch (e) {
          reportEverywhere('createSongPublicLink')(e);
          throw e;
        }
      },
    }),
    deleteSongPublicLink: build.mutation<
      void,
      { workspaceId: WorkspaceId; songId: SongId }
    >({
      query: ({ workspaceId, songId }) => ({
        url: `/share/audio/${songId}/link`,
        method: 'DELETE',
        headers: {
          'control-workspace': workspaceId,
        },
      }),
      invalidatesTags: (_result, _error, { songId }) => [
        { type: 'SongShareLink', id: songId },
      ],
    }),
    updateSongShareLink: build.mutation<ShareLink, UpdateSongShareLinkProps>({
      query: ({
        disabledDownload,
        expiresAt,
        password,
        songId,
        workspaceId,
      }) => ({
        url: `/share/audio/${songId}/link`,
        method: 'PUT',
        headers: {
          'control-workspace': workspaceId,
        },
        body: {
          password,
          expires_at: expiresAt,
          disabled_download: disabledDownload,
        },
      }),
      transformResponse: (response) => ShareLinkSchema.parse(response),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          api.util.updateQueryData(
            'getSongShareLink',
            {
              songId: args.songId,
              workspaceId: args.workspaceId,
            },
            (prev) => {
              if ('password' in args) {
                prev.password = args.password ?? null;
              }
              if ('expiresAt' in args) {
                prev.expiresAt = args.expiresAt ?? null;
              }
              if ('disabledDownload' in args) {
                prev.disabledDownload = args.disabledDownload ?? false;
              }
            }
          )
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getSongShareLink',
              {
                songId: args.songId,
                workspaceId: args.workspaceId,
              },
              constant(data)
            )
          );
        } catch (e) {
          patch.undo();
          reportEverywhere('updatePlaylistShareLink')(e);
          throw e;
        }
      },
    }),
    getProfile: build.query<Collaborator, string>({
      query: (userId) => ({
        url: `/user/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response) => CollaboratorSchema.parse(response),
    }),
    getManageUrl: build.query<AccountManageToken, void>({
      query: () => ({
        url: '/account/manage/token',
        method: 'GET',
      }),
      transformResponse: (response) => AccountManageTokenSchema.parse(response),
    }),
    prepareUpload: build.mutation<
      PrepareUploadResponse,
      {
        size: number;
        fileName: string;
        customName: string | undefined;
        audioId: SongId;
        workspaceId: WorkspaceId;
        projectId: ProjectId | undefined;
        playlistId: string | undefined;
      }
    >({
      query: ({
        size,
        fileName,
        customName,
        audioId,
        workspaceId,
        projectId,
        playlistId,
      }) => ({
        url: '/upload/s3',
        method: 'POST',
        headers: {
          'Control-Workspace': workspaceId,
        },
        body: {
          audio_id: audioId,
          custom_name: customName,
          file_name: fileName,
          playlist_id: playlistId,
          project_id: projectId,
          size,
        },
      }),
      transformResponse: (response) =>
        PrepareUploadResponseSchema.parse(response),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        if (args.playlistId) {
          const patch = dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              args.workspaceId,
              (draft) => {
                const playlist = draft.find(
                  (_) => _.playlistId === args.playlistId
                );
                if (playlist) {
                  playlist.songIds.push(args.audioId);
                }
              }
            )
          );
          try {
            await queryFulfilled;
          } catch (e) {
            patch.undo();
            reportEverywhere('prepareUpload')(e);
            throw e;
          }
        }
      },
    }),
  }),
});
