/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import clsx from 'clsx';
import React, { useState } from 'react';
import albumPlay from '../../../assets/images/album_play.svg';
import artworkPlaceholder from '../../../assets/images/default_artwork.svg';
import playlistPlaceholder from '../../../assets/images/playlist_placeholder2.svg';
import { Squircle } from '../theme/data-display/Squircle/Squircle';
import { getColorById } from '../utils/color-utils';
import styles from './Artwork.module.css';

export interface ArtworkProps {
  backgroundColor?: string;
  borderBottomRadius?: number;
  borderRadius?: number;
  borderTopRadius?: number;
  coverImagePath?: string | null;
  id?: string;
  onClick?: () => void;
  onContextMenu?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onPlayButtonClick?: (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => void;
  placeholder?: 'file' | 'playlist';
  size: number; // Size is required for the image to be center cropped correctly
  skeleton?: boolean;
  squircle?: boolean;
  title?: string;
}

// https://github.com/Tourean/control-macos-ios/blob/fa46caa5fcf7b23c27e09b2e0600e509db19dcb2/Shared/CConstants.h#L196
const defaultBorderRadius = 6;

// TODO: create Storybook stories for this component it is very complex and has various cases which render differt UIs
export default function Artwork({
  backgroundColor = undefined,
  borderBottomRadius,
  borderRadius = defaultBorderRadius,
  borderTopRadius,
  coverImagePath,
  id,
  onClick,
  onContextMenu,
  onPlayButtonClick,
  placeholder = 'file',
  size,
  skeleton,
  squircle = false,
  title,
}: ArtworkProps) {
  const color =
    placeholder === 'file' ? `#${getColorById(id || '')}` : undefined;

  const [showBtn, setShowBtn] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(
        styles.container,
        size === 0 && styles.cropped,
        squircle && styles.squircle
      )}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onMouseEnter={() => setShowBtn(true)}
      onMouseLeave={() => setShowBtn(false)}
      style={{
        width: size ? `${size}px` : '0',
        height: size ? `${size}px` : '0',
        flex: size ? `0 0 ${size}px` : undefined,
      }}
      title={title}
    >
      {squircle ? (
        <Squircle
          char={title?.charAt(0) ?? ''}
          id={id!}
          imageUrl={coverImagePath}
          size="X"
        />
      ) : skeleton ? (
        <div
          className={styles.skeletonImage}
          style={{
            position: size ? 'relative' : 'absolute',
            left: size ? 'auto' : 0,
            top: size ? 'auto' : 0,
            width: size ? `${size}px` : '100%',
            height: size ? `${size}px` : '100%',
          }}
        />
      ) : (
        <img
          className={styles.image}
          src={
            coverImagePath ??
            (placeholder === 'file' ? artworkPlaceholder : playlistPlaceholder)
          }
          style={{
            position: size ? 'relative' : 'absolute',
            left: size ? 'auto' : 0,
            top: size ? 'auto' : 0,
            background: backgroundColor || color,
            width: size ? `${size}px` : '100%',
            height: size ? `${size}px` : '100%',
            borderBottomLeftRadius: `${
              borderBottomRadius || borderRadius || 0
            }px`,
            borderBottomRightRadius: `${
              borderBottomRadius || borderRadius || 0
            }px`,
            borderTopLeftRadius: `${borderTopRadius || borderRadius || 0}px`,
            borderTopRightRadius: `${borderTopRadius || borderRadius || 0}px`,
          }}
        />
      )}
      {onPlayButtonClick && showBtn && (
        <div className={styles.playbtn}>
          <img
            height={size / 1.8}
            onClick={(e) => {
              onPlayButtonClick(e);
            }}
            src={albumPlay}
            width={size / 1.8}
          />
        </div>
      )}
    </div>
  );
}
